.baseapp {
  display: flex;
  animation: fadeIn 1s ease-in-out;
}

.dashboard {
  width: 100%;
  padding: 20px;
  transition: width 0.3s ease-out, margin-left 0.3s ease-out;
}

.dashboard.nav-open {
  width: calc(100% - 120px);
  margin-left: 120px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}