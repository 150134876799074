.home-dashboard {
    animation: fadeIn .5s ease-in-out;
  }

.title-bar {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-size: 1.5em;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }