.side-nav {
  position: absolute; /* Add this */
  width: 120px;
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  animation: fadeIn 1s ease-in-out;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}


  .side-nav.open {
    transform: translateX(0);
  }

  .side-nav-close-arrow {
    display: flex;
    justify-content: flex-end;
    padding: 20px
  }
  
  .logo-container {
    padding-top: 25px;
    width: 42px;
    height: 42px;
    padding-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .side-nav nav {
    display: flex;
    flex-direction: column;
  }

  .avatar-container {
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    padding-top: 25px;
    padding-bottom: 25px;
  }
  
  @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    