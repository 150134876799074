.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-form {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  width: 650px; 
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  animation: fadeIn 1s ease-in-out;
  flex-direction: row;
}


.login-form > *:last-child {
  margin-bottom: 0;
}

.login-image-container {
  width: 60%;
  height: 100%;
}

.login-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transform: scale(1.7) translateY(-6%) translateX(-14%);
}
.login-fields {
  display: flex;
  flex-direction: column;
  width: 50%; 
  padding-left: 30px;
  padding-right: 40px
}

.password-field {
  margin-bottom: 20px;
}

.login-logo-container {
  padding-bottom: 12px;
  height: 86px;
}

.logo {
  width: 68px;
  height: 68px;
}

.welcome-message {
  font-family: var(--font-family);
  font-size: 24px;
  color: var(--primary-color);
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
}

.welcome-to {
  font-weight: 300;
}

.run-app {
  font-weight: 700;
  color: var(--accent-color);
}

.register-link {
  margin-top: 20px;
  margin-bottom: 30px;
  text-align: center;
  font-size: 14px;
}

.register-link a {
  color: var(--accent-color);
  text-decoration: none;
  font-weight: 600;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  .login-image-container {
    display: none;
  }
  .login-form {
    width: 100%;
    height: 100vh;
    box-shadow: none;
    border: none;
    border-radius: 0;
  }
  .login-fields {
    width: 75%;
    padding: 15px;
  }
}