.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.modal-overlay.open {
  opacity: 1;
  visibility: visible;
}

.modal-content-wrapper {
  background: white;
  border-radius: 10px;
  position: relative;
  max-width: 80vw; 
  width: 60vw;
  max-height: 90vh; 
  transform: scale(0.7);
  opacity: 0;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.modal-content-wrapper.open {
  transform: scale(1);
  opacity: 1;
}

.modal-content-bar {
  position: absolute;
  top: 0px; /* Adjust as needed */
  left: 0;
  right: 0;
  height: 10px; /* Adjust as needed */
  background-color: var(--accent-color); /* Adjust as needed */
  border-radius: 10px 10px 0 0; /* Adjust as needed */
}

.modal-content {
  padding: 30px;
  overflow-y: auto; 
}