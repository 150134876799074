.avatar {
    position: relative;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .avatar:hover::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    animation: fadeIn .3s ease-in-out;
  }
  
  .avatar:hover .camera-icon {
    display: block;
    animation: fadeIn .3s ease-in-out;
  }
  
  .camera-icon {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    fill: white;
  }
  
  .avatar-image {
    width: 100%;
    height: 100%;
  }
  
  .avatar-wrapper {
    position: relative;
    display: inline-block;
  }
  
  .online-indicator {
    position: absolute;
    bottom: 1px;
    right: 1px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #34D399;
    border: 2px solid white;
  }