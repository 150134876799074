.combo-box-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .combo-box-container label {
    margin-bottom: 5px;
    font-size: 1em;
    color: #666;
  }
  
  .combo-box {
    padding: 10px;
    font-family: Arial, sans-serif;
    font-size: 1em;
    border-radius: 5px;
    border: 1px solid #ccc;
    outline: none;
    transition: border-color 0.3s ease;
  }
  
  .combo-box:focus {
    border-color: #333;
  }