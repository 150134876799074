.add-run-modal-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  
  .add-run-modal-row {
    display: flex;
    flex-direction: column;
  }
  
  .add-run-modal-button-container {
    grid-column: span 2;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }