.signup-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .create-account-message {
    font-family: var(--font-family);
    font-size: 24px;
    color: var(--accent-color);
    margin-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    font-weight: 600;
  }
  
  .signup-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    width: 500px; 
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    animation: fadeIn 1s ease-in-out;
    padding: 30px;
  }
  
  .signup-form > * {
    margin-bottom: 20px;
  }
  
  .signup-form > *:last-child {
    margin-bottom: 0;
  }

  .signup-fields {
    width: 70%;
  }

  .signup-fields button {
    margin-top: 20px;
    width: 100%;
  }

  .signup-link {
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: center;
    font-size: 14px;
  }
  
  .signup-link a {
    color: var(--accent-color);
    text-decoration: none;
    font-weight: 600;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  
@media screen and (max-width: 768px) {
    .signup-image-container {
      display: none;
    }
    .signup-form {
      width: 100%;
      height: 100vh;
      box-shadow: none;
      border: none;
      border-radius: 0;
    }
    .signup-fields {
      width: 80%;
      padding: 15px;
    }
  }