button {
    transition: all 0.4s ease;
    border: none;
    cursor: pointer;
    margin-bottom: 10px;
    background-color: transparent;
    padding: 5px;
    border-radius: 13px;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  }
  
  .active {
    border-radius: 13px;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.15);
    padding: 5px;
    background-color: #333333 !important;
  }