.add-dashboard-button {
  border: 2px dashed #f28332;
  cursor: pointer;
  margin-bottom: 10px;
  background-color: transparent;
  padding: 1px;
  border-radius: 13px;
  }
  .add-dash-icon-container{
    margin-top: 2px;
  }

  .add-dashboard-button:active {
    border-color: #333333; 
  }