/* Scrollbar styles */
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color);
}

/* Datagrid container styles */
.datagrid-container {
  width: 100%;
  height: 250px;
  overflow: auto;
}

.datagrid-container table {
  width: 100%;
  border-collapse: collapse;
}

/* Header and Footer styles */
.datagrid-container thead th,
.datagrid-container tfoot tr {
  position: sticky;
  background: white;
}

.datagrid-container thead th {
  top: 0;
}

.datagrid-container tfoot tr {
  bottom: 0;
}

/* Column and Cell styles */
th, td {
  padding: 10px;
  text-align: left;
  color: var(--primary-color);
}

th {
  cursor: pointer;
}

td {
  border-bottom: 1px solid #ddd;
  cursor: default;
}

th:last-child {
  border-right: none;
}

/* Hover styles */
.datagrid-container thead th:hover,
tbody tr:hover {
  background-color: #f5f5f5;
}

/* Footer styles */
tfoot {
  border-top: 1px solid var(--secondary-color);
}

tfoot td {
  text-align: right;
  font-weight: bold;
  border-bottom: none;
}

/* Sort arrow styles */
.sort-arrow {
  color: var(--accent-color);
}

