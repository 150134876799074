.duration-picker-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
  
  .duration-picker-container label {
    margin-bottom: 5px;
    font-size: 0.9em;
    color: #777;
  }
  
  .duration-picker {
    display: flex;
    gap: 5px;
  }
  
  .duration-picker select {
    padding: 10px;
    font-family: var(--font-family);
    font-size: var(--font-size);
    border-radius: 5px;
    border: 1px solid var(--secondary-color);
    outline: none;
    transition: border-color 0.3s ease;
  }
  
  .duration-picker select:focus {
    border-color: var(--primary-color);
  }