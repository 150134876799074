.text-field-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
  
  .text-field-container label {
    margin-bottom: 5px;
    font-size: 0.9em;
    color: #777;
  }
  
  .text-field {
    padding: 10px;
    font-family: var(--font-family);
    font-size: var(--font-size);
    border-radius: 5px;
    border: 1px solid var(--secondary-color);
    outline: none;
    transition: border-color 0.3s ease;
  }
  
  .text-field:focus {
    border-color: var(--primary-color);
  }

  .text-field-error {
    padding-left: 4px;
    color: var(--accent-color);
    font-size: 0.8em;
    margin-top: 5px;
  }

  .required-asterisk {
    color: var(--accent-color);
  }