.open-nav-button {
    margin-left: -200px;
    transition: margin-left 0.3s ease-out;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    background-color: white;
  }
  
  .open-nav-button.slide-out {
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 48px;
  }
