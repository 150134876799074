.button {
  padding: 10px 20px;
  font-size: var(--font-size);
  font-family: var(--font-family);
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: var(--hover-color);
}

.button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.button:disabled:hover {
  background-color: #cccccc;
}